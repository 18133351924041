import PageLinkComponent from '@/components/page/linkComponent';
import CustomTooltip from '@/components/popups/customTooltip';
import { queryGraphQL } from '@/data';
import { CommerceRead } from '@/data/commerce/commerce.graphql';
import { isEstimateType } from '@/helpers/useIsEstimateType';
import { CommerceStatus } from '@/pages/dashboard/commerce/components/statuses';
import { Order, QueryCommerceReadArgs } from '@/types/schema';
import { Box, Button, Chip, ChipProps, Divider, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

function SentDates( {
	sentDates,
	removeDivider,
}: {
	sentDates: string[],
	removeDivider?: boolean
} ) {
	const { t } = useTranslation();
	if ( !sentDates?.length ) return null;
	
	return (
		<Fragment>
			{!removeDivider && <Divider sx={{ my: 1 }}/>}
			<Typography color='text.secondary' px={.5}>
				{t( 'commerce:sent-on-following-dates' )}
			</Typography>
			{sentDates?.map( ( date, index ) => {
				const sentDate = new Date( date );
				return (
					<Fragment key={index}>
						<Typography px={.5}>
							{+sentDate ? format( sentDate, 'PPp' ) : '-'}
						</Typography>
						{index < sentDates.length - 1 && <Divider sx={{ my: 1 }}/>}
					</Fragment>
				);
			} )}
		</Fragment>
	);
}

function Merged( {
	commerceType,
	commerce,
	router,
	removeDivider,
}: {
	commerceType: string,
	commerce: Order,
	router: any,
	removeDivider?: boolean
} ) {
	const { t } = useTranslation();
	
	if ( !commerce?.metadata?.mergedTo ) return null;
	
	return (
		<Box
			sx={{ maxHeight: 400, overflow: 'overlay' }}
			onClick={( e ) => e.stopPropagation()}>
			{!removeDivider && <Divider sx={{ m: 1 }}/>}
			<Typography>{t( 'commerce:merged-to' )}</Typography>
			<Typography color='text.secondary'>
				{t( 'commerce:previously-merged', { commerceType: commerceType } )}
			</Typography>
			<Button
				variant='text'
				onClick={async () => {
					const { commerceRead } = await queryGraphQL<QueryCommerceReadArgs>( {
						query    : CommerceRead,
						variables: { id: commerce.metadata.mergedTo },
					} );
					if ( commerceRead ) {
						await router.push( `/dashboard/commerce/${commerceRead.type === 'ORDER'
							? 'orders'
							: isEstimateType( commerce.type )
								? 'estimates'
								: 'invoices'}/${commerce.metadata.mergedTo}` );
					}
				}}>
				{t( 'common:view-order' )}
			</Button>
		</Box>
	);
}

function TrackTransaction() {
	const { t } = useTranslation();
	return (
		<Box
			sx={{ maxHeight: 400, overflow: 'overlay' }}
			onClick={( e ) => e.stopPropagation()}>
			<Divider sx={{ m: 1 }}/>
			<Button
				variant='text'
				color='primary'
				component={PageLinkComponent}
				href='https://connect.stripe.com/app/express'
				target='_blank'>
				{t( 'commerce:track-transaction' )}
			</Button>
		</Box>
	);
}

export default function CommerceStatusChip( {
	cellData,
	clientView,
	chipProps,
	isPdf,
}: {
	cellData: any,
	clientView?: boolean,
	chipProps?: ChipProps,
	isPdf?: boolean
} ) {
	const { t } = useTranslation();
	let router;
	if ( isPdf ) {
		router = {};
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		router = useRouter();
	}
	const commerce: Order = cellData?.row?.original || cellData;
	const commerceType = commerce?.type || 'PURCHASE';
	const isPurchase = commerceType === 'PURCHASE';
	const commerceTypeLower = toLower( commerceType );
	const statusValue = cellData?.value || cellData?.status;
	const sentDates = commerce?.metadata?.sentDates;
	const MERGED_TO = commerce?.metadata?.mergedTo && 'MERGED_TO';
	const RECEIVED = statusValue === 'RECEIVED' && 'RECEIVED';
	const CANCELLED = statusValue === 'CANCELLED' && 'CANCELLED';
	const CLOSED = statusValue === 'CLOSED' && 'CLOSED';
	const PARTIALLY_RECEIVED = isPurchase && !RECEIVED && !CANCELLED && !CLOSED && commerce?.metadata?.partiallyReceived && 'PARTIALLY_RECEIVED';
	
	const COLLECTIONS = statusValue === 'COLLECTIONS' && 'COLLECTIONS';
	const WRITTEN_OFF = statusValue === 'WRITTEN_OFF' && 'WRITTEN_OFF';
	const VOIDED = statusValue === 'VOIDED' && 'VOIDED';
	const PAST_DUE = statusValue === 'PAST_DUE' && 'PAST_DUE';
	const PENDING_VERIFICATION = statusValue === 'PENDING_VERIFICATION' && 'PENDING_VERIFICATION';
	
	const SENT_DATES = sentDates?.length && ( !isPurchase || isPurchase && !RECEIVED && !CANCELLED && !CLOSED ) && 'SENT_DATES';
	
	const DRAFT = commerce?.status === 'DRAFT' && 'DRAFT';
	const ORDER_OPEN = commerce?.status === 'DRAFT' && commerce?.type === 'ORDER' && 'ORDER_OPEN';
	const ACCOUNT = commerce?.status === 'ACCOUNT' && 'ACCOUNT';
	
	const PENDING = commerce?.status === 'PENDING' && 'PENDING';
	
	const PAID = commerce?.status === 'PAID' && 'PAID';
	
	const VIEWED = commerce?.status === 'VIEWED' && 'VIEWED';
	
	const MARKED_AS_SENT = commerce?.status === 'SENT' && !sentDates?.length && 'MARKED_AS_SENT';
	
	const STANDING = commerce?.status === 'STANDING' && 'STANDING';
	
	const PARTIALLY_PAID = commerce?.status === 'PARTIALLY_PAID' && 'PARTIALLY_PAID';
	
	const REFUNDED = commerce?.status === 'REFUNDED' && 'REFUNDED';
	
	const refundedPayment = commerce?.payments?.find( ( payment ) => payment.status === 'REFUNDED' );
	
	const tooltipConfig = {
		SENT_DATES          : {
			toolTipChildren: () => (
				<Box
					sx={{ maxHeight: 400, overflow: 'overlay' }}
					onClick={( e ) => e.stopPropagation()}>
					<SentDates removeDivider sentDates={sentDates}/>
				</Box>
			),
		},
		MERGED_TO           : {
			toolTipChildren: () => (
				<Merged
					removeDivider
					commerceType={commerceType}
					commerce={commerce}
					router={router}
				/>
			),
		},
		CLOSED              : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:closed-process-tooltip', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		COLLECTIONS         : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:collections-process-tooltip', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		WRITTEN_OFF         : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:written-off-tooltip', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		VOIDED              : {
			toolTipChildren: () => (
				<Typography>
					{`The ${commerceTypeLower} has been voided.`}
				</Typography>
			),
		},
		PAST_DUE            : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:marked-as-past-due-tooltip', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		ACCOUNT             : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						{refundedPayment && commerce?.paidTotal === 0
							? 'This invoice has been refunded and will appear as waived on your statement.' : MERGED_TO
								? 'This invoice has already been issued, and once it\'s paid, the status will update automatically.'
								: t( 'commerce:unpaid-invoice-will-update' )}
					</Typography>
					<SentDates sentDates={sentDates}/>
					<Merged
						commerceType={commerceTypeLower}
						commerce={commerce}
						router={router}
					/>
				</Stack>
			),
		},
		PENDING             : {
			toolTipChildren: () => (
				<Stack>
					{commerce?.type ? (
						<Typography>
							{t( 'commerce:bank-processing-payment' )}
						</Typography>
					) : (
						<Typography>
							Your vendor has requested a price change for some of their items. View and approve the new costs.
						</Typography>
					)}
					{commerce.payments?.[ 0 ]?.type === 'Bank Transfer' || commerce.payments?.[ 0 ]?.type === 'Us Bank Account' && (
						<TrackTransaction/>
					)}
					<SentDates sentDates={sentDates}/>
				</Stack>
			),
		},
		ORDER_OPEN          : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:open-and-not-sent-yet', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		DRAFT               : {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:draft-and-not-sent-yet', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		PARTIALLY_PAID      : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						{t( 'commerce:commerce-partially-paid', { commerceType: commerceTypeLower } )}
					</Typography>
					<SentDates sentDates={sentDates}/>
				</Stack>
			),
		},
		PARTIALLY_RECEIVED  : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						This purchase order is partially fulfilled, with some items still on back order.
					</Typography>
					<SentDates sentDates={sentDates}/>
				</Stack>
			),
		},
		PAID                : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						{t( 'commerce:commerce-fully-paid', { commerceType: commerceTypeLower } )}
					</Typography>
					<SentDates sentDates={sentDates}/>
				</Stack>
			
			),
		},
		VIEWED              : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						{t( 'commerce:viewed-by-recipient', { commerceType: commerceTypeLower } )}
					</Typography>
					<SentDates sentDates={sentDates}/>
				</Stack>
			),
		},
		MARKED_AS_SENT      : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						{t( 'commerce:not-sent-client-marked-sent', { commerceType: commerceTypeLower } )}
					</Typography>
				</Stack>
			),
		},
		STANDING            : {
			toolTipChildren: () => (
				<Stack>
					{commerce?.standingActive ? (
						<Stack spacing={1}>
							<Typography>
								{t( 'commerce:commerce-recurring', { commerceType: commerceTypeLower } )}
							</Typography>
							{/*<Typography>*/}
							{/*	{t( 'commerce:commerce-will-be-sent-on', {*/}
							{/*		commerceType: commerceTypeLower,*/}
							{/*		date        : safeFormatInTimeZone( findNextOccurrenceDate( commerce.standingData ), 'PPP' ),*/}
							{/*	} )}*/}
							{/*</Typography>*/}
						</Stack>
					) : (
						<Typography>
							{t( 'commerce:commerce-recurring-not-active', { commerceType: commerceTypeLower } )}
						</Typography>
					)}
				</Stack>
			),
		},
		REFUNDED            : {
			toolTipChildren: () => (
				<Stack>
					<Typography>
						This {commerceTypeLower} has been refunded
					</Typography>
					<SentDates sentDates={sentDates}/>
				</Stack>
			),
		},
		PENDING_VERIFICATION: {
			toolTipChildren: () => (
				<Typography>
					{t( 'commerce:pending-verification-tooltip', { commerceType: commerceTypeLower } )}
				</Typography>
			),
		},
		DEFAULT             : {
			toolTipChildren: () => '',
		},
		
	};
	
	// the statuses are in order of priority
	const config = tooltipConfig[
	PENDING
	|| COLLECTIONS
	|| VOIDED
	|| CLOSED
	|| WRITTEN_OFF
	|| PAST_DUE
	|| ACCOUNT
	|| PARTIALLY_PAID
	|| PARTIALLY_RECEIVED
	|| PAID
	|| REFUNDED
	|| VIEWED
	|| SENT_DATES
	|| MARKED_AS_SENT
	|| STANDING
	|| MERGED_TO
	|| ORDER_OPEN
	|| DRAFT
	|| PENDING_VERIFICATION
	|| 'DEFAULT' ];
	
	let labelValue: string;
	let chipColor: 'warning' | 'primary' | 'success' | 'error' | 'default' | 'secondary' | 'info' | 'amethyst';
	
	if ( commerceType === 'PURCHASE' ) {
		if ( clientView ) {
			labelValue = CommerceStatus.PURCHASE[ PARTIALLY_RECEIVED || statusValue ]?.clientView;
		} else {
			labelValue = CommerceStatus.PURCHASE[ PARTIALLY_RECEIVED || statusValue ]?.label;
		}
		chipColor = CommerceStatus.PURCHASE[ PARTIALLY_RECEIVED || statusValue ]?.color;
	} else {
		if ( clientView ) {
			labelValue = CommerceStatus[ commerce.type ][ statusValue ]?.clientView;
		} else {
			labelValue = CommerceStatus[ commerce.type ][ statusValue ]?.label;
		}
		if ( commerce?.standingActive ) {
			chipColor = 'success';
		} else {
			chipColor = CommerceStatus[ commerce.type ][ statusValue ]?.color;
			
			if ( ACCOUNT ) {
				if ( refundedPayment && commerce?.paidTotal === 0 ) chipColor = 'warning';
				else if ( MERGED_TO ) chipColor = 'secondary';
			}
		}
	}
	
	const chipContent = (
		<Chip
			variant='alpha'
			color={chipColor}
			label={labelValue}
			{...chipProps}
		/>
	);
	
	if ( clientView ) {
		return chipContent;
	}
	
	return (
		<CustomTooltip placement='right' {...config}>
			{chipContent}
		</CustomTooltip>
	);
}
